/********************************************************
					RESPONSIVE REFERENCES

.col-xs-	.col-sm-	.col-md-	.col-lg-	.col-xl-
<544px		≥544px		≥768px		≥992px		≥1200px

$grid-breakpoints: (
  // Extra small screen / phone
  xs: 0,
  // Small screen / phone
  sm: 544px,
  // Medium screen / tablet
  md: 768px,
  // Large screen / desktop
  lg: 992px,
  // Extra large screen / wide desktop
  xl: 1200px
) !default;

*********************************************************/

@include media-breakpoint-up(lg) {
	.page-header {
		padding: 0 $header-inner-padding-x;
	}
	.page-content {
		padding: 1.5rem 2rem;

		.panel {
			margin-bottom: 1.5rem;
		}
	}
}

/*@include media-breakpoint-up(xl) {
	.page-header {
		padding: 0 $header-inner-padding-x;
	}
	.page-content {
		padding: 1.5rem 2rem;

		.card.panel {
			margin-bottom: 1.5rem;
		}
	}
}*/



@media only screen and ( max-width: $mobile-breakpoint-size ){

	//casuing some issues so disabled it.
	/*html {
		font-size: 18px;
	}*/

	.dropdown-menu .dropdown-menu {
		position: static;
		//display: block;
		//margin: 0;
		padding: .5rem 0 0 .75rem;
		width: 100%;
		border: 0;
		box-shadow: none;

		@include transform( scale(1) );
		opacity: 1;
		//visibility: visible;
		transition: none;
	}

	.show .dropdown-menu .dropdown-menu {
		visibility: visible;
	}

	.dropdown-menu .dropdown-multilevel > .dropdown-item:first-child:after {
		display: none;
	}

	body {
		overflow-x: hidden;
	}

	.page-logo-text {
		font-size: 1rem;
	}

	.page-content-overlay {
		background: transparent;
		transition: background 300ms;
		position: fixed;
		z-index: $depth-nav - 1;
	}

	.page-wrapper {
		padding-left:0;
		background: $white;

		.page-header {
			padding:0 $grid-gutter-width-base/2;
			width:100%;
			border-bottom: 1px solid rgba($black,0.09);

			[data-toggle="dropdown"] + .dropdown-menu {
				right: 1.5rem;
			}

		}

		.page-sidebar {
			z-index: $space + 1000;
			transition: $nav-hide-animate;
			@include translate3d (-$nav-width, 0, 0);

			position: fixed !important;
			top:0;
			bottom:0;

			.primary-nav {
				overflow: auto;
				overflow-x: hidden;
				-webkit-overflow-scrolling: touch;
				height: unquote("calc(100% - #{$header-height + $footer-height})");

				.nav-menu {
					.dl-ref {
						vertical-align: text-top;
					}
				}
			}
		}

		.page-content {
			padding: map-get($grid-gutter-widths, lg) $grid-gutter-width-base/2;
			color: #222;
			font-size: $fs-base + 1;

			min-height: calc(100vh - #{$header-height + $footer-height});

			.breadcrumb > .breadcrumb-item {
				max-width: $page-breadcrumb-maxwidth/2 - 20px;
			}

			.subheader {
				margin-bottom: 1.5rem;

				.subheader-title {
					line-height: 32px;
					font-weight: 300;
					//color: #22282d;

					small {
						font-size: 68%;
						letter-spacing: normal;
						margin-top: 0px;
						//color: #181c21;
						overflow: hidden;
						width: calc(100% - 30px);
						font-weight: 300;

					}
				}
			}

			.p-g {
				padding: map-get($grid-gutter-widths, xs);
			}
		}

		.page-footer {
			border-top: 1px solid rgba($black,0.09);
		}

	}

	.header-function-fixed {

		.page-header {
			margin-left: 0;
			left: 0;
			position: fixed;
			right: 0;
			top: 0;

		    transition: $nav-hide-animate;
		}

		.page-header,
		.page-logo {
			box-shadow: none !important;

		}

		/* this was conflicting with the new DOM change where we swtiched header with nav */
		/*&:not(.nav-function-fixed) {

			.page-sidebar {
				.page-logo {
					position: absolute !important;
					top:0px !important;
				}
			}

		}*/

		.page-content {
			margin-top: $header-height;
		}
	}

	/* Push content */
	.nav-mobile-push:not(.nav-mobile-slide-out) {

		.page-wrapper {
			.page-sidebar {
				@include translate3d (-$nav-width, 0, 0);
			}
			.page-header,
			.page-content,
			.page-footer,
			.page-footer-push {
				transition: $nav-hide-animate;
				//@include translate3d (0px, 0, 0);
			}
		}

	}

	/* Off canvas */
	.nav-mobile-slide-out {

		min-height: 100vh !important; /*new*/

		.page-wrapper {
			.page-sidebar {
				z-index: 0;
				transition:none;
				@include translate3d (0px, 0, 0);
			}

			.page-header,
			.page-content,
			.page-footer,
			.page-footer-push {
                transition: $nav-hide-animate;
			    @include translate3d (0, 0, 0);
			}

			.page-content {
				/* min-height: calc(100vh - #{$header-height + $footer-height});  no longer needed here */
				background: $page-bg;
			}
		}
	}

	/* mobile nav show & hide button */
	/* general */
	.mobile-nav-on {

		touch-action: none;

		overflow: hidden;
		height: 100vh;

		.page-sidebar {
			border-right:1px solid rgba(0,0,0,0.03);
			@include box-shadow( 0 3px 35px 3px rgba(0,0,0,0.52) );
		}

		/*.page-content:before {
			content:" ";
			position:fixed;
			z-index: $space;
			background:rgba(0,0,0,0);
			display: block;
			height: 100vh;
			width: 100vw;
			left: 0;
			top: 0;
		}*/

		.page-content-overlay {
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background: rgba($black,0.09);
		}

		&:not(.nav-mobile-push) {

			.page-sidebar {

		-webkit-transform: translate3d(0px, 0, 0) !important;
		    -ms-transform: translate3d(0px, 0, 0) !important;
		        transform: translate3d(0px, 0, 0) !important;

			}

			&.nav-function-fixed:not(.nav-function-top) {

				.page-sidebar {

		-webkit-transform: translate3d(0px, 0, 0) !important;
		    -ms-transform: translate3d(0px, 0, 0) !important;
		        transform: translate3d(0px, 0, 0) !important;

				}
			}
		}

		/* Push content */
		&.nav-mobile-push:not(.nav-mobile-slide-out) {

			.page-wrapper {

				.page-sidebar {
					left:0;
					@include translate3d (0px, 0, 0);
				}

				.page-header,
				.page-content,
				.page-footer,
				.page-footer-push {
					@include translate3d ($nav-width, 0, 0);
				}

			}

		}

		/* Off canvas turned ON*/
		&.nav-mobile-slide-out {

			.page-wrapper {

				overflow: hidden;

				.page-header,
				.page-content,
				.page-footer,
				.page-footer-push {
					@include translate3d ($nav-width, 0, 0);
				}

				/*.page-content:before {
					background:transparent !important;
				}*/

				.page-content-overlay {
					background: transparent !important;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					left: $nav-width;
				}

				.page-header,
				.page-content,
				.page-footer,
				.page-footer-push {
					box-shadow: 0 9px 0px 0px $page-bg, 0 -9px 0px 0px $page-bg, 12px 0 15px -4px rgba(0, 0, 0, 0.32), -12px 0 15px -4px rgba(0, 0, 0, 0.32);
				}

			}
		}

		/* 'not' is ON by default */
		&.nav-mobile-no-overlay {

			.page-wrapper {

				/*.page-content:before {
					background:rgba(0,0,0,0.3);
				}*/

				.page-content-overlay {
					background: transparent;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
				}

			}
		}

	}

}

@media only screen and ( max-width: map-get($grid-breakpoints, sm) ){

	/* here we turn on mobile font for smaller screens */
	/*body {
		font-family: $mobile-page-font !important;
	}*/

	/* mobile nav search */
	.mobile-search-on:not(.mobile-nav-on) {

		.page-header > * {
			display: none !important;
		}

		.search {
			display: flex !important;
			flex: 1;

			.app-forms {
				display: block !important;
				position: relative !important;
				width: 100%;

				.btn-search-close {
					display: flex !important;
					right:10px;
					width: 26px;
					height: 26px;
					font-size: 1rem;
				}

				#search-field {
					border: 1px solid $primary-500;
					padding-left: 1rem;
					padding-right: 3rem;
					width: 100%;
					max-width: none;
					background: $white;


					&:focus {
						border-color: $primary-500;
					}
				}
			}
		}

		[data-class="mobile-nav-on"] {
			display: none !important;
		}

	}

	.page-header {

		[data-toggle="dropdown"] + .dropdown-menu {
			width: calc(100% - #{$grid-gutter-width-base / 1.5}) !important;
			right: $grid-gutter-width-base / 3 !important;
		}
	}

	.page-header,
	.page-content {
		padding-left: $grid-gutter-width-base/3 !important;
		padding-right: $grid-gutter-width-base/3 !important;
	}


	.card[class*='p-'] .card-header,
	.card[class*='p-'] .card-body {
		padding: 0 !important;
	}

	.subheader-block {
		display: none;
	}
	.primary-nav .nav-menu li a > .badge {
		font-size: 10px !important;
	}


	.card {
		.card-header,
		.card-body {
			padding: 1rem;
		}
	}
	.alert,
	.panel .panel-tag,
	.accordion .card .card-header .card-title {
		padding: 1rem;
	}
}
