/* You can add global styles to this file, and also import other style files */

/* #THEME IMPORTS
========================================================================== */

/* Vendor bundles */
@import './scss/bootstrap';
@import './scss/app.icons';
@import "./custom/plugins/sweetalert2/sweetalert2";
@import "./node_modules/ngx-toastr/toastr";

/* App bundles */
@import './scss/app.core';

/* Skim master */
@import './scss/_skins/skin-master';
@import './scss/_imports/_global-import';

body {
  background-color: white;
}

.breadcrumb {
  background-color: #6C6766;
  color: white;
}

input.datepicker-calendar-range{
  width: 100%;
  min-width: 180px;
  height: calc(1.47em + 1rem + 2px);
  padding: 0.5rem 0.875rem;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.47;
  color: #495057;
  vertical-align: middle;
  background: #fff;
  border: 1px solid #E5E5E5;
  border-radius: 4px;
  position: relative;
}
.md-drppicker{
  top: 55px !important;
  display: flex;
  padding: 0!important;

  .ranges ul li{
    white-space: nowrap;
    button.active{
      background-color: #a38cc6!important;
      border-radius: 0!important;
    }
  }
  .calendar.left{
    border-left: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    margin-left: 0;
    margin-right: 0;
  }
  .calendar.right{
    border-bottom: 1px solid #ddd;
  }
  td{
    color: #000;
  }
  td.available:hover{
    border-radius: 0!important;
    background-color: #ffd274!important;
  }
  td.off{
    color: #999;
  }
  td.in-range{
    background-color: #ffe3a7!important;
  }
  td.active{
    background-color: #967bbd!important;
    &.in-range{
      background-color: #967bbd!important;
      &:hover{
        background-color: #886ab5;
      }
    }
    &:hover{
      background-color: #886ab5;
    }
  }
  .btn{
    background-color: #f5f5f5!important;
    line-height: inherit!important;
    color: #000!important;
    text-transform: none!important;
    font-weight: bold;
    padding: 4px 8px!important;
    font-size: 12px;
    box-shadow: none!important;
    background-image: none!important;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    &:hover{
      border: 1px solid #c6c6c6!important;
    }
    &.btn-default{
      background-color: #1dc9b7!important;
      border-color: #1dc9b7;
      color: #fff!important;
      margin-right: 8px;
      &:hover{
        background-color: #18a899!important;
        border-color: #179c8e!important;
      }
    }
  }
  .calendar-time .select .select-item{
    font-size: 12px!important;
    border-bottom: none!important;
  }
}

.show {
  display: block;
}

.hidden {
  display: none;
}

.form-overlay {
  position: relative;
  filter: blur(1px);
}
.form-overlay::before {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #FFFFFF;
  opacity: 50%;
  content: ' ';
  z-index: 3;
}

.swal2-icon.swal2-warning {
  border-color: #fd3995;
  color: #fd3995;

  .swal2-icon-content {
    color: #fd3995;
  }
}

@media (min-width: 720px) {
  .md-drppicker {
    left: auto !important;
    right: 12px !important;
  }
}


@media (max-width: 719px) {
  .md-drppicker {
    flex-direction: column;
    width: 100%;
    left: 12px !important;
    right: 12px !important;
  }
}
