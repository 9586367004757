.header-icon {
	color: $base-text-color;
	height: $header-height;
	display: block;
	line-height: $header-height;
	text-decoration: none;
	position: relative;

	&:not(.btn) {
		min-width: $header-non-btn-width;
		text-align: center;
		overflow: visible;

		>[class*='fa-']:first-child,
		>.#{$cust-icon-prefix}:first-child {
			color: $header-link-color;
		    vertical-align: middle;
			}
			>[class*='fa-']:first-child {
				font-size: $header-icon-size;
			}
			>.#{$cust-icon-prefix}:first-child {
				font-size: $header-icon-size;
			}

			&:hover {

				&>[class*='fa-']:only-child,
				&>.#{$cust-icon-prefix} {
				color: $header-link-hover-color;
				}
			}


		&[aria-controls="dropdown-menu"] {

			&[aria-controls="dropdown-menu"] {
				&:after {
					content: " ";
					width: 1.5rem;
					height: 1.5rem;
					position: absolute;
					background: #dae1e8;
					border-radius: 50%;
					top: ($header-height - 1.5rem) /2;
					z-index: -1;
					left: 0.9375rem;
					opacity: 0;
					transition: all 100ms ease-in;
				}
			}


			&[aria-expanded="true"] {
				color: $header-link-hover-color;
				/* new lines for arrow visibility */
				position: relative;
				z-index: $depth-header + 1;


				font-weight: 500;


				&:after {
					content: " ";
					width: 2.5rem;
					height: 2.5rem;
					top: ($header-height - 2.5rem ) /2;
					z-index: -1;
					left: 0.3125rem;
					opacity: 1;

				}

				>[class*='fa-']:first-child,
				>.#{$cust-icon-prefix}:first-child {
					color: $header-link-hover-color !important;
					-webkit-background-clip: initial;
					-webkit-text-fill-color: initial;
					background: none;
				}

			}

			/* header dropdowns */
			/* note: important rules to override popper's inline classes */
			& + .dropdown-menu {
				position:absolute;
				border: $theme-border-utility-size solid $header-border-color;
				right: 2rem; //same as page padding
				top: $header-height - 0.0625rem !important;
				left: auto !important;
				padding: 0;
				margin: 0;
			}

			/* end header dropdowns */

		}

		.profile-image {
			width: $profile-image-width-md;
			height:auto;
		}

	}

	&:hover{
		cursor:default;
		color:$header-link-hover-color;
	}
}

.page-header {
	background-color: $header-bg;
	box-shadow: 0px 0px 28px 0px $header-border-bottom-color;
	display: flex;

	flex: 0 0 auto;

	align-items:center;

	height: $header-height;
	position: relative;
	z-index: $depth-header;

	order: 1;

	.page-logo {
		display: none;
	}

	.badge-icon {

		left: $header-badge-left;
		top: $header-badge-top;

		&:only-child {
			position: relative;
			left: auto;
			right: auto;
			font-size: $fs-md;
			height: 26px;
			width: 26px;
			line-height: 21px;
			top: 20px;
			margin: 0 auto;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

}
